import React, { Component } from 'react'
import { picture, makePictureTree } from 'filestack-adaptive'
import objectFitImages from 'object-fit-images'
import { knuthShuffle } from 'knuth-shuffle'
import MersenneTwister from 'mersenne-twister'
import '../style/slideshow.css'

const mt = new MersenneTwister()
if(typeof window !== 'undefined') require('lazysizes')

export default class Slideshow extends Component {
  constructor(props) {
    super(props)

    this.componentDidUpdate = this.componentDidUpdate.bind(this)
    this.rotate = this.rotate.bind(this)
    this.start = this.start.bind(this)

    const slides = knuthShuffle(this.props.data.clients.slice()).filter(
      client => client.active && client.type === 'Representation' && client.media.length > 0
    ).map(
      client => Object.assign({}, client, {
        clicked: false,
        index: -1,
        trees: client.media.map(
          media => this.getMediaTree(media, {
            alt: client.name
          })
        )
      })
    )

    this.state = {
      slides: slides,
      index: slides.length
    }
  }

  componentDidUpdate() {
    if(this.props.viewport.width > 1019 && this.state.interval) this.stop()
    if(this.props.viewport.width <= 1019 && !this.state.interval) this.start(3000)
  }

  rotate() {
    // Increment slideshow index
    if(this.state.index === undefined) return
    let index = this.state.index + 1
    if(index >= this.state.slides.length) index = 0

    // Increment slide index
    let slides = this.state.slides
    let slide = slides[index]
    slide.clicked = true
    slide.index = slide.index + 1
    slide.height = 90 + (mt.random() > 0.5 ? 1 : -1) * mt.random() * 15
    if(slide.index >= slide.media.length) slide.index = 0

    this.setState({
      slides: slides,
      index: index
    })

    this.props.setActive(slide)
  }

  start(interval) {
    this.setState({ interval: setInterval(() => this.rotate(), interval) })
    setTimeout(() => document.body.classList.add('transition'), Math.min(500, interval / 4))
  }

  stop() {
    clearInterval(this.state.interval)
    this.setState({ interval: undefined })
    setTimeout(() => document.body.classList.remove('transition'), 250)
  }

  getMediaTree(media, options) {
    let tree = makePictureTree(media.handle, {
      alt: options.alt,
      formats: [
        'webp',
        'jpg'
      ],
      sizes: {
        '(max-width: 800px)': '100vw',
        // Assume the image will be viewed at 110vh
        // (the max possible size under randomization)
        fallback: `${110 / media.height * media.width}vw`
      }
    })
    tree.sources = tree.sources.map(source => this.getLazy(source))
    tree.img = this.getLazy(tree.img)
    tree.placeholder = {
      className: 'placeholder',
      style: {
        backgroundImage: `url(https://cdn.filestackcontent.com/resize=width:1/compress/${media.handle})`
      }
    }

    return (style) => (
      <picture key={media.url} {...style}>
        {tree.sources.map((s, i) => <source key={i} {...s} />)}
        <img {...tree.img} />
        <div {...tree.placeholder} />
      </picture>
    )
  }

  getLazy(attributes) {
    if(attributes.hasOwnProperty('src')) {
      attributes['data-src'] = this.compress(attributes.src)
      attributes.className = 'lazyload'
      delete attributes.src
    }

    if(attributes.hasOwnProperty('srcSet')) {
      attributes['data-srcset'] = this.compress(attributes.srcSet)
      delete attributes.srcSet
    }

    return attributes
  }

  compress(url) {
    return /,/.test(url)
      ? url.split(',').map(url => url.replace(/\/(?=[^\/]*$)/, '/compress/')).join(',')
      : url.replace(/\/(?=[^\/]*$)/, '/compress/')
  }

  componentDidMount() {
    if(!this.props.client) {
      this.rotate()
      if(this.props.viewport.width > 1019 && this.state.interval) this.stop()
      if(this.props.viewport.width <= 1019 && !this.state.interval) this.start(3000)
    }

    objectFitImages()
  }

  render() {
    return (
      <section className="Slideshow" onClick={() => this.props.viewport.width > 1019 && this.rotate()}>
        <ul className="SlideshowSlides">
          {this.state.slides.map((slide, i, a) => (
            <li
              key={slide.id}
              data-slide={i}
              data-name={slide.name}
              data-image={Math.max(slide.index, 0)}
              data-visible={slide.clicked}
              data-previous={i === (this.state.index > 0
                ? this.state.index - 1
                : this.state.slides.length - 1)}
              data-active={i === this.state.index}
              data-next={i === (this.state.index < this.state.slides.length - 1
                ? this.state.index + 1
                : 0)}>
              <style type='text/css' dangerouslySetInnerHTML={{ __html: `
                li[data-visible='true'][data-slide='${i}'][data-image='${Math.max(slide.index, 0)}'] picture:nth-of-type(${Math.max(slide.index, 0) + 1}) {
                  visibility: visible;
                  z-index: ${i <= this.state.index ? a.length - (this.state.index - i) : i - this.state.index};
                }` }} />
              {slide.trees && slide.trees.map(tree => tree({
                style: {
                  width: `${slide.height * slide.media[Math.max(slide.index, 0)].width / slide.media[Math.max(slide.index, 0)].height}vh`,
                  height: `${slide.height}vh`
                }
              }))}
            </li>
          ))}
        </ul>
        <ul className="SlideshowList">
          {this.state.slides.map((slide, i, a) => [
            <li key={slide.id} data-active={i === this.state.index} data-name={slide.name}></li>,
            <br key={`${slide.id}-br`} />
          ])}
        </ul>
      </section>
    )
  }
}
