import React from 'react'

import '../style/footer.css'

const Footer = ({ data }) => data ? (
  <footer className='Footer'>
    <nav>
      <button onMouseEnter={() => document.body.classList.toggle('footer-active')}>Info</button>
    </nav>
    <main>
      <div>
        <section data-section='about'>
          <header>About</header>
          <p dangerouslySetInnerHTML={{ __html: data.hosts[0].about.replace(/\n/g, '<br />')}} />
        </section>
        <section data-section='contact'>
          <header>Contact</header>
          {data.hosts[0].contacts.filter(
            contact => contact.type === 'Email'
          ).map(
            (contact, i) => (
              <p key={`${contact.method}-${i}`}>
                <a href={`mailto:${contact.method}`}>
                  {contact.method}
                </a>
              </p>
            )
          )}
        </section>
        <section data-section='instagram'>
          <header>Instagram</header>
          {data.hosts[0].contacts.filter(
            contact => contact.type === 'Instagram'
          ).map(
            (contact, i) => (
              <p key={`${contact.method}-${i}`}>
                <a href={`https://www.instagram.com/${contact.method.replace('@', '')}`}>
                  {contact.method}
                </a>
              </p>
            )
          )}
        </section>
        <section data-section='our-brands'>
          <header>Our Brands</header>
          <main>
            <ClientList data={data.clients.filter(
              client => client.active && client.type === 'Representation'
            )} />
          </main>
        </section>
        {data.clients.filter(
          client => client.active && client.type === 'Consulting'
        ).length > 0 && (
          <section>
            <header>Consulting Clients</header>
            <ClientList data={data.clients.filter(
              client => client.active && client.type === 'Consulting'
            )} />
          </section>
        )}
        <section data-section='former-clients'>
          <header>Former Clients</header>
          <ClientList data={data.clients.filter(
            client => !client.active
          )} />
        </section>
        <section data-section='site-credit'>
          <header>Site Credit</header>
          <a className='hover-reveal' href='https://studio.sl' data-content='Spevack Loeb' data-href='studio.sl'></a>
        </section>
      </div>
    </main>
  </footer>
) : null

const ClientList = ({ data }) => (
  <div className='ClientList'>
    {data.map(
      client =>
        client.url
          ? <p key={client.id}>
              <a
                className='hover-reveal'
                href={client.url}
                data-content={client.name}
                data-href={client.url.replace(/https?:\/\/(www\.)?/, '').replace(/\/(.*)/, '')}
              ></a>
            </p>
          : <p key={client.id}>{client.name}</p>
    )}
  </div>
)

export default Footer
