import React, { Component } from 'react'

import '../style/cursor.css'

export default class Cursor extends Component {
  constructor() {
    super()

    this.handleMouseMove = this.handleMouseMove.bind(this)

    this.state = {
      moved: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseMove(e) {
    if([undefined, null].indexOf(this.cursor) === -1) {
      this.cursor.style.left = `${e.clientX}px`
      this.cursor.style.top = `${e.clientY}px`
    }

    if(document.body.classList.contains('unmoved')) {
      document.body.classList.remove('unmoved')
    }
  }

  render() {
    const { client } = this.props
    return (
      <div className='Cursor' ref={(element) => this.cursor = element}>
        {client
          ? client.name
          : 'Loading'
        }
      </div>
    )
  }
}
