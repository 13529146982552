import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import App from './app'
import Header from './header'
import Footer from './footer'

import 'reset-css'
import '../style/style.css'
import '../style/fontface.css'

const Layout = ({ children, data, ...rest }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          egg {
            clients {
              active
              id
              media {
                handle
                url
                width
                height
              }
              name
              type
              url
            }
            hosts {
              about
              color {
                hex
              }
              contacts {
                method
                type
              }
              id
              name
              url
            }
          }
        }
      `}
      render={({ egg }) => {
        const hex = egg.hosts[0].color.hex
        return (
          <div>
            <Helmet>
              <html lang="en" amp={undefined} />
              <link rel="canonical" href={egg.hosts[0].url} />
              <title>{egg.hosts[0].name}</title>
              <meta name="og:title" content={egg.hosts[0].name} />
              <meta name="description" content={egg.hosts[0].about.replace(/(\n)+/g, ' ')} />
              <meta name="og:description" content={egg.hosts[0].about.replace(/(\n)+/g, ' ')} />
              <meta name="og:type" content="website" />
              {egg.clients.map(client =>
                client.media.map(media =>
                  <meta key={media.url} name="og:image" content={media.url} />
              )).reduce((accumulator, value, index) => {
                accumulator.push(...value)
                return accumulator
              }, [])}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="manifest" href="/manifest.json" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffb000" />
              <link rel="shortcut icon" href="/favicon.ico" />
              <meta name="msapplication-TileColor" content="#00a300" />
              <meta name="msapplication-config" content="/browserconfig.xml" />
              <meta name="theme-color" content="#ffffff" />
              <body className="rendering unmoved" />
            </Helmet>
            <Header data={egg} />
            <style type="text/css" dangerouslySetInnerHTML={{ __html: `
              .Footer > main {
                background: ${hex};
              }

              .SlideshowList li::after {
                color: ${hex};
              }
            `}} />
            <div>
              {children && children.map(child =>
                React.cloneElement(child, { egg })
              )}
            </div>
            {typeof document !== "undefined"
              && <App data={egg} />
            }
            <Footer data={egg} />
          </div>
        )
      }}
    />
  )
}

export default Layout
