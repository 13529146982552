import React, { Component } from 'react'
import Helmet from 'react-helmet'
import debounce from 'throttle-debounce/debounce'

import Cursor from './cursor'
import Slideshow from './slideshow'

export default class App extends Component {
	constructor(props) {
		super(props)

		this.setActive = this.setActive.bind(this)
		this.watchResize = debounce(100, this.watchResize).bind(this)
		this.breakpoints = [1019]
		this.state = {
			client: undefined,
			viewport: {
				width: document.documentElement.clientWidth
			}
		}

		window.addEventListener('resize', this.watchResize, true)
		document.addEventListener('mouseenter', this.watchAnchorHover, true)
		document.addEventListener('mouseleave', this.watchAnchorHover, true)
		document.addEventListener('click', this.closeFooter, true)
	}

	setActive(client) {
		this.setState({
			client: client
		})
	}

	watchResize(e) {
		this.breakpoints.forEach(breakpoint => {
			if(this.state.viewport.width <= breakpoint && document.documentElement.clientWidth > breakpoint) {
				this.setState({ viewport: { width: document.documentElement.clientWidth } })
			}
			if(this.state.viewport.width > breakpoint && document.documentElement.clientWidth <= breakpoint) {
				this.setState({ viewport: { width: document.documentElement.clientWidth } })
			}
		})
	}

	closeFooter(e) {
		if(['A', 'BUTTON', 'INPUT'].indexOf(e.target.tagName) > -1) return
		if(!document.body.classList.contains('footer-active')) return

		document.body.classList.remove('footer-active')
		e.stopPropagation()
	}

	watchAnchorHover(e) {
		if(['H1', 'A', 'BUTTON', 'INPUT'].indexOf(e.target.tagName) === -1) return

	  switch(e.type) {
	    case 'mouseenter':
	      document.body.classList.add('hover')
	      break
	    case 'mouseleave':
	      document.body.classList.remove('hover')
	      break
			default:
				break
	  }
	}

	render() {
		return (
			<div id="App">
				<Helmet>
					<body className="rendered unmoved" />
				</Helmet>
				{this.state.viewport.width > 1019
				 	&& <Cursor client={this.state.client} />
				}
				<Slideshow
					data={this.props.data}
					client={this.state.client}
					setActive={this.setActive}
					viewport={this.state.viewport} />
			</div>
		)
	}
}
