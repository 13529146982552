import React from 'react'

import '../style/header.css'

const Header = ({ data }) => data ? (
  <header className='Header'>
    <h1>{data.hosts[0].name}</h1>
  </header>
) : null

export default Header
